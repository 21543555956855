var columns = [{
  title: "User",
  dataIndex: "nickname",
  key: "nickname"
}, {
  title: "Hood Id",
  dataIndex: "hoodid",
  key: "hoodid"
}, {
  title: "金额",
  dataIndex: "amount",
  key: "amount",
  scopedSlots: {
    customRender: "amount"
  }
}, {
  title: "资金类型",
  dataIndex: "pumptype",
  key: "pumptype",
  scopedSlots: {
    customRender: "pumptype"
  }
}, {
  title: "当前状态",
  dataIndex: "status",
  key: "status",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "时间",
  dataIndex: "pumptime",
  key: "pumptime",
  scopedSlots: {
    customRender: "pumptime"
  }
}, {
  title: "Tx hash",
  dataIndex: "txhash",
  key: "txhash",
  width: 200,
  scopedSlots: {
    customRender: "txhash"
  }
}];
export { columns };