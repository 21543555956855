var searchKeys = [{
  key: "nickname",
  label: "User",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "hoodid",
  label: "Hood Id",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "pumptype",
  label: "资金类型",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "cycle",
  label: "Round",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "status",
  label: "状态",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "txhash",
  label: "Tx hash",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };